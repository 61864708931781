<template>
  <div v-if="!isMobileHelper()">
    <w-flex class="row justify-center xs12">
      <div class="container text-left ml10 xs12 md8">
        <h1 id="title">Privacy Policy</h1>
        <p class="headline my5">
          The protection of your data is very important to us. We therefore
          process your data exclusively on the basis of the statutory provisions
          (GDPR, TKG 2003). In this data protection declaration we inform you
          about the most important aspects of data processing.
        </p>
        <p class="title2">
          Contact
        </p>
        <p class="headline">
          If you contact us via the contact form on our website or by email, the
          IP data of the subscriber as well as your name, email address and
          telephone number are saved for six months in order to process the
          request and for any follow-up questions saved us. These data will not
          be passed on without your consent.
        </p>
        <p class="title2">
          Data Storage Customer File
        </p>
        <p class="headline">
          If you contact us via the contact form on our website or by email, the
          IP data of the subscriber as well as your name, email address and
          telephone number are saved for six months in order to process the
          request and for any follow-up questions saved us. These data will not
          be passed on without your consent.
        </p>
        <p class="title2">
          Publication on the website
        </p>
        <p class="headline">
          In order to promote mediation activity and achieve the greatest
          possible success in mediation, we publish your name on an overall list
          of artists on our website www.opera4u.com under the heading “Artists”.
          We will also publish your name, your personal photo and a summary of
          your biography under a separate link to your person.
        </p>

        <p class="headline">
          There is no data transfer to third parties, with the exception of the
          transfer of the data listed to our internet service provider and our
          cloud provider. We have concluded a corresponding contract for order
          data processing with the provider.
        </p>

        <p class="headline">
          The data will be published on the website during the ongoing business
          relationship.
        </p>

        <p class="headline">
          The data processing takes place on the basis of the legal regulations
          of § 96 Abs 3 TKG as well as Art 6 Abs 1 lit a DSGVO (consent) and /
          or Art 6 Abs 1 lit b DSGVO (necessary for the fulfillment of the
          contract).
        </p>

        <p class="title2">
          Publication on social media
        </p>
        <p class="headline">
          We also publish information about your involvement at events on social
          media for the purpose of increasing your profile and promoting your
          artistic activity. This information includes your name, the artistic
          activity you took on at the event (role) and, if applicable, your
          biography.
        </p>

        <p class="headline">
          We currently use Facebook as a social media service from the provider
          Facebook Inc. with its company headquarters at 1601 Willow Road, Menlo
          Park, CA 94025 USA. The specified data is transferred to the
          provider's server, stored there and published on our Facebook profile
          page. The data will therefore be passed on to a non-European third
          country. The relationship with the provider is based on the Privacy
          Shield Agreement. We have concluded a corresponding contract for order
          data processing with the provider.
        </p>

        <p class="headline">
          TThe data will be stored at Facebook Inc. as long as this is necessary
          so that Facebook Inc. can offer products and services. The data
          remains stored with Facebook Inc. until our profile account is
          deleted, unless Facebook Inc. no longer needs the data to offer
          products and services.
        </p>

        <p class="headline">
          The data processing takes place on the basis of the legal regulations
          of § 96 Abs 3 TKG as well as Art 6 Abs 1 lit a DSGVO (consent) and /
          or Art 6 Abs 1 lit b DSGVO (necessary for the fulfillment of the
          contract).
        </p>

        <p class="title2">
          Supplier and contractor administration (to process our purchasing and
          back office)
        </p>
        <p class="headline">
          We receive the data of your name, commercial register number, address,
          email address, telephone number and account data from you as part of
          the relationship as a supplier and / or contractor of our company.
          This data is required to fulfill the contract and is also processed to
          fulfill legal obligations (e.g. tax retention obligations or warranty
          and compensation obligations). You are not obliged to provide us with
          data; However, if you do not provide the data, then we cannot provide
          or claim the service.
        </p>

        <p class="headline">
          There is no data transfer to third parties, with the exception of the
          transfer of the account data to the processing bank for the purpose of
          transferring the purchase price / fee, to our cloud provider to store
          the data mentioned and to our tax advisor to fulfill our tax
          obligations.
        </p>

        <p class="headline">
          We store the data during the business relationship and beyond for a
          period of 30 years after the end of the financial year in which the
          business case was ended, in particular in order to be able to meet tax
          retention obligations and as long as there are statutory or
          contractual claims (in particular warranty and damage claims ) make it
          necessary for the data to be processed, whereby these are then
          processed in an archive with separate access regulations.
        </p>

        <p class="title2">
          Cookies
        </p>
        <p class="headline">
          Our website uses cookies. These are small text files that are stored
          on your device with the help of the browser. They do no harm. We use
          cookies to make our offer user-friendly. Some cookies remain stored on
          your device until you delete them. They enable us to recognize your
          browser the next time you visit. If you do not want this, you can set
          up your browser so that it informs you about the setting of cookies
          and you only allow this in individual cases. If you deactivate
          cookies, the functionality of your website may be restricted.
        </p>

        <p class="title2">
          Your Rights
        </p>

        <p class="headline">
          In principle, you have the right to information, correction, deletion,
          restriction, data portability, revocation and objection. If you
          believe that the processing of your data violates data protection law
          or that your data protection claims have been violated in any other
          way, you can complain to the supervisory authority, which is the data
          protection authority in Austria.
        </p>

        <h1 id="title">Imprint</h1>

        <p class="title2">
          Corporate Purpose
        </p>

        <p class="headline">
          The placement of opera singers, conductors and directors as well as
          the placement and organization of concerts and "master classes".
        </p>

        <p class="title2">
          Terms Of Use
        </p>

        <p class="title2">
          1. Rights to the content of the website / copyright - trademarks /
          trademark rights
        </p>

        <p class="headline">
          This website and its entire content are the (intellectual) property of
          Opera4u.com GmbH or third parties. All rights are reserved unless
          expressly stated otherwise. Opera4u.com GmbH endeavors to observe the
          copyright to the images, graphics, sound documents, video sequences
          and texts used in all publications and to use images, graphics, sound
          documents, video sequences and texts created by Opera4u.com GmbH
          itself or license-free. All brands and trademarks named on the
          website, possibly protected for third parties, are subject, without
          exception and without restriction, to the applicable trademark
          protection or trademark law and the ownership rights of the respective
          owner. The copyright for published images, graphics, sound documents,
          Video sequences and texts remain with Opera4u.com GmbH; any
          duplication or use in other publications of any kind requires the
          prior express consent of Opera4u.com GmbH.
        </p>

        <p class="title2">
          2. Data protection
        </p>

        <p class="headline">
          Any transmission of personal or business data (such as name, (email)
          address, telephone number) by email or in any other way when using
          this website is made by the user on an expressly voluntary basis. All
          transmitted data / information / materials are treated as
          non-confidential and freely usable. Any use of contact data published
          in the imprint or comparable information by third parties for the
          purpose of transmitting information that has not been expressly
          requested is prohibited.
        </p>

        <p class="title2">3. Liability</p>

        <p class="headline">
          The content of the website can be changed at any time and is made
          available in the form and presentation as it appears in each case
          without any guarantee or other assurance of any kind whatsoever; this
          in particular, but not exclusively, without any assurance of a certain
          usability or usability or freedom from third party rights. Any
          liability of Opera4u.com GmbH or other third parties who were / are
          involved in the design or implementation of this website for any
          damage or other disadvantages in connection with or from the use of
          this website is excluded. In particular, Opera4u.com GmbH assumes no
          liability for the completeness, topicality, correctness or quality of
          the information provided. All offers are non-binding.
        </p>

        <p class="title2">4th Hyperlinks</p>

        <p class="headline">
          The website may contain links to third party websites. In the absence
          of any means of influence and control, Opera4u.com GmbH is not able to
          control the content of these websites. Opera4u.com GmbH is free of any
          responsibility for its content, security or the behavior of the
          operator. Any liability on the part of Opera4u.com GmbH with regard to
          any links is excluded, in particular, if these websites or their
          content violate third-party intellectual property rights; contain
          incorrect, incomplete or misleading information; are not suitable for
          a specific use or a specific use or do not have the required security.
        </p>

        <p class="title2">5. Applicable law and place of jurisdiction</p>

        <p class="headline">
          For all disputes in connection with these conditions and the use of
          this website, the applicability of Austrian law (to the exclusion of
          the UN sales law and the provisions of international private law or
          comparable matters of other domestic or EU legal regulations) as well
          as the exclusive jurisdiction of the relevant court in Vienna agreed.
        </p>
      </div>
      <div class="container text-left ml10 mt12 xs8 md4">
        <h2 style="color: #640800;">Opera4u.com GmbH</h2>
        <p class="headline pt2">
          Josefstädter Straße 30/2 <br />A-1080 Vienna Austria <br />Tel .: +
          43-1-5137592 <br />agency (@) opera4u.com <br /><a
            href="www.opera4u.com"
            style="color: #640800;"
            >www.opera4u.com</a
          >
        </p>

        <h2 style="color: #640800;" class="pt5">Managing Director:</h2>
        <p class="headline pt2">
          Michael Gruber & Mag. Leo Krischke <br /><br />
          UID -Nr: ATU 63938458 <br />Commercial <br />Register of the
          Commercial Court<br />Vienna to FN 303088v <br />Commercial authority
          like, ECG: <br />Magistratisches Bezirksamt for the <br />6th and 7th
          district <br />Legal form: Company with limited liability
        </p>

        <p class="headline">
          Bank: Bank Austria
          <br />Account number: 95044467/00<br />Bank code: 11000 <br />IBAN:
          AT74 1100 0095 0444 6700 <br />BIC: BKAUATWW<br /><br />
          Team photos: © by picturesborn / Nessler
        </p>

        <h2 style="color: #640800;" class="pt5">Web Design & Programming:</h2>
        <p class="headline pt2">
          <a
            href="https://www.linkedin.com/in/gabrycina/"
            style="color: #640800;"
            >https://www.linkedin.com/in/gabrycina/</a
          >
        </p>
        <a href="https://firmen.wko.at/opera4ucom-gmbh/wien/?firmaid=0f62b618-a1a6-4a36-acc2-5e648db463b8&standortid=1&standortname=wien&suchbegriff=opera4u&page=1">
            <img class="mt10" src="../assets/wko.png" alt="WKO">
        </a>
      </div>
    </w-flex>
  </div>

  <div v-else>
    <w-flex class="column justify-center xs12">
      <div class="container text-left xs10 md8">
        <h1 id="title">Privacy Policy</h1>
        <p class="headline my5">
          The protection of your data is very important to us. We therefore
          process your data exclusively on the basis of the statutory provisions
          (GDPR, TKG 2003). In this data protection declaration we inform you
          about the most important aspects of data processing.
        </p>
        <p class="title2">
          Contact
        </p>
        <p class="headline">
          If you contact us via the contact form on our website or by email, the
          IP data of the subscriber as well as your name, email address and
          telephone number are saved for six months in order to process the
          request and for any follow-up questions saved us. These data will not
          be passed on without your consent.
        </p>
        <p class="title2">
          Data Storage Customer File
        </p>
        <p class="headline">
          If you contact us via the contact form on our website or by email, the
          IP data of the subscriber as well as your name, email address and
          telephone number are saved for six months in order to process the
          request and for any follow-up questions saved us. These data will not
          be passed on without your consent.
        </p>
        <p class="title2">
          Publication on the website
        </p>
        <p class="headline">
          In order to promote mediation activity and achieve the greatest
          possible success in mediation, we publish your name on an overall list
          of artists on our website www.opera4u.com under the heading “Artists”.
          We will also publish your name, your personal photo and a summary of
          your biography under a separate link to your person.
        </p>

        <p class="headline">
          There is no data transfer to third parties, with the exception of the
          transfer of the data listed to our internet service provider and our
          cloud provider. We have concluded a corresponding contract for order
          data processing with the provider.
        </p>

        <p class="headline">
          The data will be published on the website during the ongoing business
          relationship.
        </p>

        <p class="headline">
          The data processing takes place on the basis of the legal regulations
          of § 96 Abs 3 TKG as well as Art 6 Abs 1 lit a DSGVO (consent) and /
          or Art 6 Abs 1 lit b DSGVO (necessary for the fulfillment of the
          contract).
        </p>

        <p class="title2">
          Publication on social media
        </p>
        <p class="headline">
          We also publish information about your involvement at events on social
          media for the purpose of increasing your profile and promoting your
          artistic activity. This information includes your name, the artistic
          activity you took on at the event (role) and, if applicable, your
          biography.
        </p>

        <p class="headline">
          We currently use Facebook as a social media service from the provider
          Facebook Inc. with its company headquarters at 1601 Willow Road, Menlo
          Park, CA 94025 USA. The specified data is transferred to the
          provider's server, stored there and published on our Facebook profile
          page. The data will therefore be passed on to a non-European third
          country. The relationship with the provider is based on the Privacy
          Shield Agreement. We have concluded a corresponding contract for order
          data processing with the provider.
        </p>

        <p class="headline">
          TThe data will be stored at Facebook Inc. as long as this is necessary
          so that Facebook Inc. can offer products and services. The data
          remains stored with Facebook Inc. until our profile account is
          deleted, unless Facebook Inc. no longer needs the data to offer
          products and services.
        </p>

        <p class="headline">
          The data processing takes place on the basis of the legal regulations
          of § 96 Abs 3 TKG as well as Art 6 Abs 1 lit a DSGVO (consent) and /
          or Art 6 Abs 1 lit b DSGVO (necessary for the fulfillment of the
          contract).
        </p>

        <p class="title2">
          Supplier and contractor administration (to process our purchasing and
          back office)
        </p>
        <p class="headline">
          We receive the data of your name, commercial register number, address,
          email address, telephone number and account data from you as part of
          the relationship as a supplier and / or contractor of our company.
          This data is required to fulfill the contract and is also processed to
          fulfill legal obligations (e.g. tax retention obligations or warranty
          and compensation obligations). You are not obliged to provide us with
          data; However, if you do not provide the data, then we cannot provide
          or claim the service.
        </p>

        <p class="headline">
          There is no data transfer to third parties, with the exception of the
          transfer of the account data to the processing bank for the purpose of
          transferring the purchase price / fee, to our cloud provider to store
          the data mentioned and to our tax advisor to fulfill our tax
          obligations.
        </p>

        <p class="headline">
          We store the data during the business relationship and beyond for a
          period of 30 years after the end of the financial year in which the
          business case was ended, in particular in order to be able to meet tax
          retention obligations and as long as there are statutory or
          contractual claims (in particular warranty and damage claims ) make it
          necessary for the data to be processed, whereby these are then
          processed in an archive with separate access regulations.
        </p>

        <p class="title2">
          Cookies
        </p>
        <p class="headline">
          Our website uses cookies. These are small text files that are stored
          on your device with the help of the browser. They do no harm. We use
          cookies to make our offer user-friendly. Some cookies remain stored on
          your device until you delete them. They enable us to recognize your
          browser the next time you visit. If you do not want this, you can set
          up your browser so that it informs you about the setting of cookies
          and you only allow this in individual cases. If you deactivate
          cookies, the functionality of your website may be restricted.
        </p>

        <p class="title2">
          Your Rights
        </p>

        <p class="headline">
          In principle, you have the right to information, correction, deletion,
          restriction, data portability, revocation and objection. If you
          believe that the processing of your data violates data protection law
          or that your data protection claims have been violated in any other
          way, you can complain to the supervisory authority, which is the data
          protection authority in Austria.
        </p>

        <h1 id="title">Imprint</h1>

        <p class="title2">
          Corporate Purpose
        </p>

        <p class="headline">
          The placement of opera singers, conductors and directors as well as
          the placement and organization of concerts and "master classes".
        </p>

        <p class="title2">
          Terms Of Use
        </p>

        <p class="title2">
          1. Rights to the content of the website / copyright - trademarks /
          trademark rights
        </p>

        <p class="headline">
          This website and its entire content are the (intellectual) property of
          Opera4u.com GmbH or third parties. All rights are reserved unless
          expressly stated otherwise. Opera4u.com GmbH endeavors to observe the
          copyright to the images, graphics, sound documents, video sequences
          and texts used in all publications and to use images, graphics, sound
          documents, video sequences and texts created by Opera4u.com GmbH
          itself or license-free. All brands and trademarks named on the
          website, possibly protected for third parties, are subject, without
          exception and without restriction, to the applicable trademark
          protection or trademark law and the ownership rights of the respective
          owner. The copyright for published images, graphics, sound documents,
          Video sequences and texts remain with Opera4u.com GmbH; any
          duplication or use in other publications of any kind requires the
          prior express consent of Opera4u.com GmbH.
        </p>

        <p class="title2">
          2. Data protection
        </p>

        <p class="headline">
          Any transmission of personal or business data (such as name, (email)
          address, telephone number) by email or in any other way when using
          this website is made by the user on an expressly voluntary basis. All
          transmitted data / information / materials are treated as
          non-confidential and freely usable. Any use of contact data published
          in the imprint or comparable information by third parties for the
          purpose of transmitting information that has not been expressly
          requested is prohibited.
        </p>

        <p class="title2">3. Liability</p>

        <p class="headline">
          The content of the website can be changed at any time and is made
          available in the form and presentation as it appears in each case
          without any guarantee or other assurance of any kind whatsoever; this
          in particular, but not exclusively, without any assurance of a certain
          usability or usability or freedom from third party rights. Any
          liability of Opera4u.com GmbH or other third parties who were / are
          involved in the design or implementation of this website for any
          damage or other disadvantages in connection with or from the use of
          this website is excluded. In particular, Opera4u.com GmbH assumes no
          liability for the completeness, topicality, correctness or quality of
          the information provided. All offers are non-binding.
        </p>

        <p class="title2">4th Hyperlinks</p>

        <p class="headline">
          The website may contain links to third party websites. In the absence
          of any means of influence and control, Opera4u.com GmbH is not able to
          control the content of these websites. Opera4u.com GmbH is free of any
          responsibility for its content, security or the behavior of the
          operator. Any liability on the part of Opera4u.com GmbH with regard to
          any links is excluded, in particular, if these websites or their
          content violate third-party intellectual property rights; contain
          incorrect, incomplete or misleading information; are not suitable for
          a specific use or a specific use or do not have the required security.
        </p>

        <p class="title2">5. Applicable law and place of jurisdiction</p>

        <p class="headline">
          For all disputes in connection with these conditions and the use of
          this website, the applicability of Austrian law (to the exclusion of
          the UN sales law and the provisions of international private law or
          comparable matters of other domestic or EU legal regulations) as well
          as the exclusive jurisdiction of the relevant court in Vienna agreed.
        </p>
      </div>
      <div class="container text-left ml10 mt12 xs8 md4">
        <h2 style="color: #640800;">Opera4u.com GmbH</h2>
        <p class="headline pt2">
          Josefstädter Straße 30/2 <br />A-1080 Vienna Austria <br />Tel .: +
          43-1-5137592 <br />agency (@) opera4u.com <br /><a
            href="www.opera4u.com"
            style="color: #640800;"
            >www.opera4u.com</a
          >
        </p>

        <h2 style="color: #640800;" class="pt5">Managing Director:</h2>
        <p class="headline pt2">
          Michael Gruber & Mag. Leo Krischke <br /><br />
          UID -Nr: ATU 63938458 <br />Commercial <br />Register of the
          Commercial Court<br />Vienna to FN 303088v <br />Commercial authority
          like, ECG: <br />Magistratisches Bezirksamt for the <br />6th and 7th
          district <br />Legal form: Company with limited liability
        </p>

        <p class="headline">
          Bank: Bank Austria
          <br />Account number: 95044467/00<br />Bank code: 11000 <br />IBAN:
          AT74 1100 0095 0444 6700 <br />BIC: BKAUATWW<br /><br />
          Team photos: © by picturesborn / Nessler
        </p>

        <h2 style="color: #640800;" class="pt5">Web Design & Programming:</h2>
        <p class="headline pt2">
          <a
            href="https://www.linkedin.com/in/gabrycina/"
            style="color: #640800;"
            >linkedin.com/in/gabrycina/</a
          >
        </p>
        <a href="https://firmen.wko.at/opera4ucom-gmbh/wien/?firmaid=0f62b618-a1a6-4a36-acc2-5e648db463b8&standortid=1&standortname=wien&suchbegriff=opera4u&page=1">
            <img class="mt10" src="../assets/wko.png" alt="WKO">
        </a>
      </div>
    </w-flex>

  </div>
</template>

<script>
export default {
  name: "Imprint",
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  p {
    color: #666666;
    font-size: 1vw;
  }

  h1 {
    font-weight: 400;
  }

  h2 {
    font-size: 1.2vw;
  }

  @media (max-width: 40rem) {
    margin-left: 10vw;
    margin-right: 10vw;

    p {
      font-size: 6vw;
    }

    h2 {
        font-size: 6vw;
    }
  }
}

p {
  margin-bottom: 2vh;
}

.headline {
  font-weight: 300;
}

.title2 {
  font-weight: 700;
}

$shadow: rgba(0, 0, 0, 0.2);

#title {
  font-size: 5vw;
  padding-top: 3vw;
  padding-bottom: 1vw;
  @media (max-width: 40rem) {
    font-size: 12vw;
  }
  color: #640800;
}
</style>
